import React from 'react';

const GooglePlayButton = ({ url }) => {

    return (
        <a target="_blank" title="Baixar no Google Play" rel="noreferrer" className="" href={url}>
            <img className="h-16 w-auto" src="/img/google-play-badge.png" alt="Download on Google Play"/>
        </a>
    );
}

export default GooglePlayButton;