import { React, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import GenericGamePage from '../components/GenericGamePage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import games from '../data/games';
import SEO from '../components/SEO'

const Game = () => {

  let { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  if ( slug in games ) {
    let game = games[slug];

    return (
      <div className="bg-gray-100">
        <SEO
          title={`${game.title} - Trapcat`}
          description={game.description}
          keywords={`jogos, ${game.title}`}
          author="Trapcat"
          type="article" 
          image_url={`https://trapcat.com/img/trapcat-og.png`}
          url={`https://trapcat.com/jogos/${slug}`}
          />
        <Header menu={[]}/>
        <GenericGamePage data={game} />
        <Footer />
      </div>
    );
  }
  else {
    return (<Navigate to="/" />);
  }
  
}

export default Game;
