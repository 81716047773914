import { Link } from 'react-router-dom';
import AppStoreButton from './AppStoreButton';
import GooglePlayButton from './GooglePlayButton';


const GenericGamePage = ({ data }) => {

    return (
        <section id="game" className="min-h-screen px-10 pt-48 pb-32">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10">
                <div className='w-full md:px-10'>
                    {data.trailer &&
                        <div className="video-wrapper">
                            <iframe className="iframe rounded-lg" src={data.trailer} title={data.title} frameborder="0" allow="autoplay;" allowfullscreen></iframe>
                        </div>}
                    {!data.trailer &&
                        <img className="w-auto h-auto rounded-lg" src={data.image} alt={data.title} />}
                </div>
                <div className="text-left">
                    <h1 className="mt-5 chollasans text-black text-4xl md:text-5xl font-bold">
                        {data.title}
                    </h1>
                    <p className="metropolis text-black mt-6 text-xl leading-8">
                        {data.description}
                    </p>
                    <div className="mt-10 w-full flex items-center">
                        <div className="md:flex md:items-center">
                            {data.appStoreLink && <div className="mt-4">
                                <AppStoreButton url={data.appStoreLink} />
                            </div>}
                            {data.googlePlayLink && <div className={data.appStoreLink && "md:ml-8 mt-4"}>
                                <GooglePlayButton url={data.googlePlayLink} />
                            </div>}
                            {!data.appStoreLink && !data.googlePlayLink && <div className="mb-4">
                                <span className="text-lg">Em breve</span>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-20 flex justify-center">
                <Link to={'/'} className="bg-white rounded-lg chollasans bg-white text-xl text-black font-bold px-8 py-3 transition-all hover:scale-105  hover:shadow-lg">« Voltar</Link>
            </div>
        </section>
    );
}

export default GenericGamePage;