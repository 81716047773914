import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({title, description, keywords, author, type, image_url, url}) {

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords} />
            <meta name="author" content={author} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image_url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image_url} />
            <meta name="twitter:card" content={type} />
            <link rel="canonical" href={url} />
        </Helmet>
    )
}