import React from 'react';

const ContactUsButton = ({}) => {

    return (
        <a href="mailto:contato@trapcat.com" className="inline-flex items-center justify-center w-full px-5 py-2 text-md font-medium text-center text-white bg-trapcat-blue rounded-lg sm:w-auto hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 chollasans">
            Fale conosco
        </a> 
    );
}

export default ContactUsButton;