import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

const MenuButton = ({item, toggleMenu}) => {
    return (
        <Link 
            to={item.hash}
            onClick={toggleMenu}
            activeClass="text-trapcat-blue"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="inline-flex cursor-pointer items-center justify-center w-full px-2 py-2 text-md font-medium text-center text-gray-700 sm:w-auto hover:text-gray-900 chollasans">
                {item.title}
        </Link>
    );
}

export default MenuButton;