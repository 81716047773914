import React from 'react';

const AppStoreButton = ({ url }) => {

    return (
        <a target="_blank" title="Baixar na App Store" rel="noreferrer" className="" href={url}>
            <img className="h-16 w-auto" src="/img/app-store-badge.png" alt="Download on App Store"/>
        </a>
    );
}

export default AppStoreButton;