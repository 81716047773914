import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './scenes/Home';
import Game from './scenes/Game';

const App = () => {
  return (
    
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/jogos/:slug" element={<Game />} />
      </Routes>
    </Router>
  );
}

export default App;
