let games = {
    "maranhao-encantado": {
        slug: "maranhao-encantado",
        image: "/img/maranhao-encantado.jpg",
        icon: "/img/maranhao-encantado-icon.png",
        trailer: "https://www.youtube.com/embed/ZfLazT9hhzo",
        title: "Maranhão Encantado",
        description: "Combine os encantos do folclore maranhense para conhecer suas histórias e vencer!",
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.Trapcat.MaranhaoEncantado",
        appStoreLink: "https://apps.apple.com/br/app/maranh%C3%A3o-encantado/id6443440416",
    },
    "dragon-escape": {
        slug: "dragon-escape",
        image: "/img/dragon-escape.jpg",
        icon: "/img/dragon-escape-icon.png",
        trailer: "https://youtube.com/embed/GDemZ8Lxc08",
        title: "Dragon Escape",
        description: "Ajude o dragão a escapar do poço de lava! Voe para a esquerda ou para a direita, desviando dos inimigos e suba o máximo que puder!",
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.trapcat.DragonEscape",
        appStoreLink: null,
    },
    "quadra-monsters": {
        slug: "quadra-monsters",
        image: "/img/quadra-monsters.jpg",
        icon: "/img/quadra-monsters-icon.png",
        trailer: "https://www.youtube.com/embed/CofWLfu5Ha0",
        title: "Quadra Monsters",
        description: "Pares de monstrinhos irão cair do céu e seu trabalho é agrupar quatro ou mais para libertá-los dos feitiços do terrível mago Zamichat.",
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.Trapcat.QuadraMonsters1",
        appStoreLink: "https://apps.apple.com/br/app/quadra-monsters-1/id6450796599",
    },
    "bumba": {
        slug: "bumba",
        image: "/img/bumba.jpg",
        icon: "/img/bumba-icon.png",
        trailer: "https://www.youtube.com/embed/guOO3eORSwg",
        title: "Bumba",
        description: "Toque rítmos e explore cenários e personagens genuinamente maranhenses para completar as peças do bumba-meu-boi do Maranhão.",
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.tavernainteractive.Bumba",
        appStoreLink: "https://apps.apple.com/br/app/bumba/id884567129?l=en",
    },
};

export default games;