import { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUsButton from '../components/ContactUsButton';
import MenuButton from '../components/MenuButton';

const Header = ({menu}) => {
    let [showMenu, setShowMenu] = useState(false);

    let toggleMenu = () => setShowMenu(!showMenu);

    if (showMenu)
        document.body.classList.add('open');
    else
        document.body.classList.remove('open');

    return (
        <header id="inicio" className="fixed w-full shadow-sm z-50">
            <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-900 ">
                <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
                    <Link to="/" className="flex items-center">
                        <div className="block dark:hidden">
                            <img src="/img/trapcat-logo.png" className="h-6 mr-3 sm:h-9" alt="Trapcat Inc Logo" />
                        </div>
                        <div className="hidden dark:block">
                            <img src="/img/trapcat-logo-white.png" className="h-6 mr-3 sm:h-9" alt="Trapcat Inc Logo" />
                        </div>
                    </Link>
                    <div className="flex items-center md:order-1 lg:hidden">
                        <button onClick={toggleMenu} data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className={`items-center justify-center w-full lg:flex lg:w-auto order-1 lg:order-2 ${showMenu ? "flex" : "hidden"}`} id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 lg:flex-row lg:space-x-8 lg:mt-0 chollasans text-lg font-bold">
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <MenuButton toggleMenu={toggleMenu} item={item}/>
                                </li>
                            ))}
                            <li>
                                <ContactUsButton/>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;