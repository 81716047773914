import games from '../data/games';
import { Link } from 'react-router-dom';
import ContactUsButton from '../components/ContactUsButton';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link as ScrollLink } from "react-scroll";
import SEO from '../components/SEO'

const Home = () => {

    return (
        <>
        <SEO
          title="Trapcat - Aplicações interativas para sua empresa"
          description="De simuladores industriais em realidade virtual à jogos educativos, criamos experiências personalizadas de entretenimento interativo usando tecnologia que se destacam no mercado e agregam valor à sua empresa."
          keywords={`desenvolvimento de aplicações, aplicações interativas, jogos, soluções personalizadas, inovação empresarial`}
          author="Trapcat"
          type="article" 
          image_url={`https://trapcat.com/img/trapcat-og.png`}
          url={`https://trapcat.com/`}
          />
        <Header menu={[
            { hash: "início", title: "Início"},
            { hash: "serviços", title: "Serviços"},
            { hash: "jogos-autorais", title: "Jogos Autorais"},
        ]}/>
    
        <section id="início" className="bg-white dark:bg-gray-900">
            <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white chollasans">
                        Aplicações interativas <br/>para sua empresa
                    </h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                        De simuladores industriais em realidade virtual à jogos educativos, criamos experiências personalizadas de entretenimento interativo usando tecnologia que se destacam no mercado e agregam valor à sua empresa.
                    </p>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                        <ContactUsButton/>
                        <ScrollLink 
                            to="serviços"
                            smooth={true}
                            duration={500}
                            className="inline-flex cursor-pointer items-center justify-center w-full px-5 py-3 text-md font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 chollasans">
                                Saiba mais
                        </ScrollLink> 
                    </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                    <img src="./img/hero.jpg" alt="A virtual reality headset"/>
                </div>                
            </div>
        </section>
        
        {/** Clients */}
        <section className="bg-white dark:bg-gray-900">
            <p className="font-light text-gray-500 mb-2 md:text-lg lg:text-xl dark:text-gray-400 text-center">
                Nossos clientes
            </p>
            <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16 flex justify-center">
                <img src="./img/clients.jpg" alt="List of clients" className="max-h-16 w-auto"/>
            </div>
        </section>
        
        {/** Serviços */}
        <section id="serviços" className="bg-gray-50 dark:bg-gray-800">
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white chollasans">Infinitas possibilidades</h2>
                        <p className="mb-8 font-light lg:text-xl">No mundo virtual podemos dar asas à nossa imaginação! Confira os serviços que podem gerar valor para o seu negócio:</p>
                        
                        <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700 chollasans">
                            {
                                ["Simuladores", "Jogos educativos", "Jogos para promoção de marca", "Gamificação", "Jogos para saúde e pesquisa"].map((item, index) => (
                                <li key={index} className="flex space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-trapcat-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-lg font-medium leading-tight text-gray-900 dark:text-white">{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <img className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="./img/feature1.png" alt="Ilustração de rapaz usando óculos de realidae virtual"/>
                </div>
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <img className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="./img/feature2.png" alt="Ilustração de um mundo virtual: Um caminho de abóboras."/>
                    <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white chollasans">Paixão por jogos e interatividade</h2>
                        <p className="mb-8 font-light lg:text-xl">Acreditamos que é possível contar histórias de maneiras muito mais interessantes utilizando interatividade e imersão, com foco total no jogador. Veja algumas das vantagens de nossa metodologia:</p>
                        <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700 chollasans">
                        {
                            ["Engajamento e Atenção", "Aprendizado Ativo", "Feedback Imediato", "Motivação Inerente", "Metrificação de resultados"].map((item, index) => (
                            <li key={index} className="flex space-x-3">
                                <svg className="flex-shrink-0 w-5 h-5 text-trapcat-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                <span className="text-lg font-medium leading-tight text-gray-900 dark:text-white">{item}</span>
                            </li>
                        ))}
                    </ul>
                    </div>
                    
                </div>
            </div>
        </section>

        {/** Jogos */}
        <section id="jogos-autorais" className="bg-white dark:bg-gray-900">
            <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
                <div className="col-span-2 mb-8">
                    <p className="text-lg font-medium text-trapcat-blue">Audiencia Global</p>
                    <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white chollasans">Nossos jogos autorais rodam o mundo!</h2>
                    <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">A diversão não tem fronteiras. Explore nossos jogos autorais e descubra experiências únicas que unem jogadores de todos os continentes.</p>
                    <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                        {/*<div>
                        <a href="#" className="inline-flex items-center text-base font-medium text-trapcat-blue hover:text-cyan-500 ">
                            Ver todos os jogos
                            <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a>
                        </div>*/}
                    </div>
                </div>
                <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                    {Object.values(games).map((game, index) => 
                        (
                            <Link key={index} to={'/jogos/' + game.slug} >
                                <div key={index} className="mb-8 md:mb-0 hover:scale-105 transition-all">
                                    <img src={game.icon} className="w-16 h-16 rounded-lg" alt={"Ícone " + game.title}/>
                                    <h3 className="mb-2 text-2xl font-bold dark:text-white chollasans mt-2">{game.title}</h3>
                                    <p className="font-light text-gray-500 dark:text-gray-400">{game.description}</p>
                                </div>
                            </Link>
                        )
                    )}
                </div>
            </div>
        </section>
        
        {/** Testemunhos */}
        <section className="bg-gray-50 dark:bg-gray-800">
            <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">
                        Jogo muito divertido, as músicas são lindas e o melhor: Feito no Brasil! Valorizando nossa cultura. Aplicativo leve e de fácil utilização, indico para crianças mas os adultos também irão se apaixonar (e se viciar... rsrsrs)
                        </p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src="./img/avatar.png" alt="Avatar"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Bruna Silva</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Sobre o jogo Bumba</div>
                        </div>
                    </figcaption>
                </figure>
            </div>
        </section>
    
        {/** Contato */}
        <section className="bg-white dark:bg-gray-800">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
                <div className="max-w-screen-sm mx-auto text-center">
                    <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white chollasans">Vamos conversar!</h2>
                    <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Entre em contato para falarmos de propostas e possíveis parcerias!</p>
                    <ContactUsButton/>
                </div>
            </div>
        </section>
        <Footer/>
    </>
    );
}

export default Home;
